<template>
  <section class="tiss-container">
    <BatchHeaderContainer
      :title="batch.name"
      :batch="batch"
      :handleInvoiceSituation="updateBatchSituation"
      :openManageGuides="openManageGuides"
      @finishBatch="batch => $emit('finishBatch', batch)"
      @openInfo="openInfoSidebar"
      :openModalBatchFilesUpload="openModalBatchFilesUpload"
    />
    <CardsContainer :batch="batch" @changeShow="showTable = !showTable" :show="showTable"/>
    <section v-show="showTable">
      <LotFilter @change="onChangeFilter" class="no-print" />
      <GuidesTable
        :checkable="checkable"
        :headers="headers"
        :subHeaders="subHeaders"
        :rows="rows"
        :getGuide="getGuide"
        :openHistoric="openHistoric"
        :openDocumentModal="openDocumentModal"
        :openDocumentListModal="openDocumentListModal"
        :loading="loading"
        :status="batch.status"
      />
      <div class="pagination-position">
        <b-pagination
          size="sm"
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
        />
      </div>
    </section>
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { parseNumberToMoney, parseMoneyToNumber } from '@/utils/moneyHelper'
import { mapState } from "vuex"
import api from '@/modules/sus/api'

export default {
  name: 'LotContainer',
  components: {
    CardsContainer: () => import('@/modules/sus/manageGuides/layouts/CardsContainer'),
    GuidesTable: () => import('@/components/Sus/Table/GuidesTable.vue'),
    BatchHeaderContainer: () => import('@/modules/sus/manageGuides/components/BatchHeaderContainer'),
    LotFilter: () => import('@/components/Sus/Generic/Filters/LotFilter.vue'),
  },
  props: {
    title: String,
    batch: Object,
    getGuide: Function,
    openHistoric: Function,
    openDocumentModal: Function,
    openFileModal: Function,
  },
  async created() {
    await this.getGuides()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      checkable: false,
      rows: [],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      filters: [],
      headers: [
        'Atualização',
        'Paciente',
        'Folha',
        'N° da guia',
        'Prof. executante',
        'Faturado',
        'Receber',
        'Ver menos'
      ],
      subHeaders: this.batch.type === "App\\Models\\Sus\\ApacGuide" ?
        [
          'Qtde.',
          'Cód Procedimento',
          'Descrição',
          'Cód APAC'
        ]:
        [
          'CID',
          'Qtde.',
          'Cód Procedimento',
          'Descrição',
        ],
      loading: false,
      cards: [],
      showTable: false
    }
  },
  computed: {
    ...mapState({
      batches: state => state.tiss.batches,
    }),
    canFinishBatch() {
      return this.batch.status === 'Aguardando pagamento'
    },
    canWaitPayment() {
      const finishableStatus = ['Paga', 'Pag. Parcial', 'Glosada']
      return this.rows.every(row => finishableStatus.includes(row['Situação']))
    }
  },
  async mounted() {
    await this.$root.$on('update-manage-guides', async () => {
      if (this.batch?.id === this.batches?.batch?.id) {
        if (this.$store.getters["tiss/getReloadContent"]) {
          await this.getGuides()
          await this.openManageGuides()
          await this.$store.dispatch('tiss/reloadContent', false)
        }
      }
    })
  },
  methods: {
    parseNumberToMoney,
    parseMoneyToNumber,
    openDocumentListModal(tissGuide){
      this.$emit('openDocumentListModal', tissGuide)
    },
    openInfoSidebar(batchId) {
      this.$emit('openInfo', batchId)
    },
    async openManageGuides() {
      const data = {
        healthPlan: this.title,
        batch: this.batch,
        cards: this.cards,
        rows: this.rows,
      }

      if (!this.reloadContent) {
        await this.$store.dispatch('tiss/updateBatches', data)
      }

      this.$emit('onManageGuides', data)
    },
    changeBatchStatus(status) {
      this.batch.status = status
    },
    async updateBatchSituation(status) {
      const props = {
        status: status,
        clinic_id: this.clinic.id
      }
      const isLoading = this.$loading.show()
      try {
        await api.updateSusBatch(this.batch.id, props)
        this.changeBatchStatus(status)
        if (status === 'Finalizado' || status === 'Enviado') {
          this.$emit('onUpdate', this.batch)
        }
        this.$toast.success('Situação atualizada com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getGuides() {
      this.loading = true
      try {
        const { data } = await api.getSusGuidesByBatchId(
          this.batch.id,
          this.currentPage,
          this.filters
        )
        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.totalItems = data.total
        this.rows = []

        if (data.data.length) {
          this.fillArray(data.data)
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    finishBatch() {
      console.log('Finish batch ', this.batch.id);
    },
    fillArray(row) {
      row.map(guide => {
        const guideRow = {
          id: guide.id,
          checked: false,
          'Atualização': this.parseDate(guide.updated_at),
          'Paciente': guide?.patient?.name ?? '-',
          'Folha': '-',
          'N° da guia': guide?.guide?.guide_number ?? '-',
          'Prof. executante': guide?.professional?.name ?? '-',
          'Faturado': parseNumberToMoney(guide?.total_value),
          'Receber': parseNumberToMoney(0),
          'Detalhes': [],
          'guide_id': guide.guide.id
        }

        const items = []
        guide.guide.procedures.map(procedure => {
          const item = guide.guide_type === "App\\Models\\Sus\\ApacGuide" ?
          {
            'Qtde.': procedure?.quantity ?? '-',
            'Cód Procedimento': procedure?.item?.code ?? '-',
            'Descrição': procedure?.item?.name ?? '-',
            'Cód APAC': guide?.apac_authorization_number
          }:
          {
            'CID': procedure?.cid?.code ?? '-',
            'Qtde.': procedure?.quantity ?? '-',
            'Cód Procedimento': procedure?.item?.code ?? '-',
            'Descrição': procedure?.item?.name ?? '-',
          };

          items.push(item)
        })            

        guideRow.Detalhes = items
        this.rows.push(guideRow)
      })


      this.$emit('updateGuideRows', this.rows)
    },
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    getTypes(type) {
      type = type.split('\\').at(-1)
      const types = {
        TissGuideConsultation: 'Consulta',
        TissGuideSpSadt: 'SP/SADT',
        TissGuideHospitalizationRequest: 'Solicitação de internação',
        TissGuideFee: 'Honorários',
        TissGuideHospitalizationSummary: 'Resumo de internação',
        TissGuideOtherExpense: 'Despesas'
      }
      return types[type]
    },
    async onChangeFilter(filter) {
      this.filters = filter
      await this.getGuides()
    },

    openModalBatchFilesUpload(id) {
      this.$emit('openFileModal', id)
    }
  },
  watch: {
    batch: {
      handler: async function () {
        if(this.show) await this.getGuides()
      },
      deep: true
    },
    currentPage: {
      handler: async function () {
        if(this.show) await this.getGuides()
      }
    },
    show: {
      handler: async function () {
        if(this.show && this.row?.length) await this.getGuides()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tiss-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid var(--neutral-300);
  border-radius: 8px;
  margin-bottom: 30px;
}
</style>
